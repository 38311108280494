//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { getDefaultFuseOptions, transformEntityName } from '@/utils'
import { multiSearch } from '@/utils/Common.ts'
import { debounce, format } from 'quasar'
import Fuse from 'fuse.js'
import { contactsStore } from '@/store'

const { capitalize } = format

let searchFn = null

export default {
  name: 'MemberPicker',
  components: {
    'close-icon': createSVGComponent({ icon: 'close', size: 14, sizeStorage: 16 }),
    'search-icon': createSVGComponent({ icon: 'search', size: 20 }),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    erroredMembers: {
      type: Object,
      default: null,
    },
    getClassFromMember: {
      type: Function,
      default: () => undefined,
    },
    contactFilter: {
      type: Function,
      default: () => true,
    },
    draggableContacts: {
      type: Boolean,
      default: false,
    },
    hideInfo: {
      type: Boolean,
      default: false,
    },
    canSelectSelf: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      focusList: false,
      nameFilter: '',
      currentSelectedIndex: 0,
      dropdownPositionTop: false,
      searchResults: [],
      searchFnValid: false,
    }
  },
  computed: {
    filteredContacts () {
      const { getters } = this.$store
      const list = [...contactsStore.getters.contactList]
      this.canSelectSelf && list.push(getters.profile)
      return list
        .filter(contact => this.value.indexOf(contact.jid) < 0)
        .filter(this.contactFilter)
        .sort((a, b) => a.displayName > b.displayName ? 1 : -1)
    },
    otherContacts () {
      return this.nameFilter.trim() === '' ? this.filteredContacts : this.searchResults
    },
    contactsToAdd () {
      return this.value.map(jid => {
        return contactsStore.getters.contact(jid) || {}
      })
    },
    listHeight () {
      const { listPadding, itemHeight, maxItemDisplay, maxListHeight } = this.dropdownOptions
      if (this.otherContacts.length < maxItemDisplay) return this.otherContacts.length * itemHeight + listPadding * 2 + 2

      return maxListHeight
    },
    member () {
      return capitalize(this.$t('glossary.member_plural'))
    },
  },
  watch: {
    focusList (value, previousValue) {
      value && !previousValue && (this.currentSelectedIndex = 0)

      if (value && this.otherContacts.length > 0) {
        this.checkDropdownPosition()
      }
    },
    nameFilter () {
      this.currentSelectedIndex = 0

      const options = getDefaultFuseOptions(['displayName'])
      if (!this.searchFnValid) {
        const fuse = new Fuse(this.filteredContacts, options)
        searchFn = text => fuse.search(text)
        this.searchFnValid = true
      }

      // return searchFn(this.addContactFilter).map(i => i.item)
      this.searchResults = multiSearch(searchFn, this.nameFilter)
    },
    filteredContacts () {
      this.searchFnValid = false
    },
    currentSelectedIndex () {
      this.$nextTick(() => {
        const { scrollableArea } = this.$refs
        if (!scrollableArea) return

        const container = scrollableArea.$el
        if (!container) return

        const targetElement = container.querySelector('.dropdown__item--selected')
        if (!targetElement) return

        const { top, bottom } = container.getBoundingClientRect()
        const { y, height } = targetElement.getBoundingClientRect()

        const targetYCenter = y + height / 2

        if (targetYCenter > top && targetYCenter < bottom) return

        const offset = targetYCenter >= bottom
          ? (y - bottom + height)
          : (targetYCenter <= top ? -(top - y) : 0)

        const currentOffset = scrollableArea.getDistanceFromTop()
        scrollableArea.scrollTo(currentOffset + offset)
      })
    },
  },
  created () {
    this.dropdownOptions = {
      listPadding: 4,
      itemHeight: 44,
      maxItemDisplay: 6,
    }

    if (this.isFocused) {
      this.$nextTick(() => {
        this.$refs.nameInput.focus()
      })
    }

    const { listPadding, itemHeight, maxItemDisplay } = this.dropdownOptions
    this.dropdownOptions.maxListHeight = maxItemDisplay * itemHeight + listPadding * 2 + 2

    this.debouncedChangeFilter = debounce(this.debouncedChangeFilter, 150)
  },
  beforeDestroy () {
    this.debouncedChangeFilter && this.debouncedChangeFilter.cancel()
  },
  methods: {
    icon (jid) {
      return contactsStore.getters.contactIcon(jid)
    },
    debouncedChangeFilter (event) {
      const { target } = event
      if (!target) return

      const { value } = target
      this.nameFilter = value
    },
    contactDragStart (jid, event) {
      event.dataTransfer.effectAllowed = 'link'
      event.dataTransfer.setData('tadateam/x-contact', jid)
      event.dataTransfer.setDragImage(event.target, 50, 14)
      return true
    },
    clearSearchInput () {
      this.$refs.nameInput.value = ''
      this.nameFilter = ''
    },
    checkDropdownPosition () {
      const { add } = this.$refs
      if (!add) return

      const { bottom } = add.getBoundingClientRect()
      const freeSpace = window.innerHeight - bottom

      this.dropdownPositionTop = this.listHeight > freeSpace
    },
    transformEntityName,
    toggleListFocus (event) {
      this.focusList = event.type === 'focus'

      if (event.type === 'keyup') {
        const { target } = event
        target && target.blur && target.blur()
      }
    },
    handleInputKeydown (event) {
      const code = event.key || event.keyCode // .which and .keyCode are deprecated, see MDN
      let manualKeyHandle = false
      let moveBy = 0
      switch (code) {
        case 'Enter':
        case 13: {
          const contact = this.otherContacts[this.currentSelectedIndex]
          contact && this.toggleContactSelection(contact.jid)
          break
        }
        case 'ArrowDown':
        case 40: {
          manualKeyHandle = true
          moveBy = 1
          break
        }
        case 'ArrowUp':
        case 38: {
          manualKeyHandle = true
          moveBy = -1
          break
        }
        case 'Backspace':
        case 8: {
          if (this.$refs.nameInput.value.length === 0) {
            const newValue = [...this.value]
            newValue.pop()
            this.$emit('input', newValue)
          }
          break
        }
      }
      if (manualKeyHandle) {
        event.preventDefault()
      }
      if (moveBy !== 0) {
        const length = this.otherContacts.length
        const current = this.currentSelectedIndex
        const target = current + moveBy
        target > length - 1
          ? this.currentSelectedIndex = 0
          : target < 0
            ? this.currentSelectedIndex = length - 1
            : this.currentSelectedIndex = target
      }
    },
    handleMouseEvent (event) {
      if (event.type === 'mousedown') {
        event.preventDefault()

        const { target } = event
        const jid = target && target.getAttribute && target.getAttribute('data-jid')
        jid && this.toggleContactSelection(jid)
        return
      }
      this.currentSelectedIndex = 0
    },
    toggleContactSelection (jid) {
      const index = this.value.indexOf(jid)
      const newValue = [...this.value]
      if (index >= 0) {
        newValue.splice(index, 1)
      } else {
        newValue.push(jid)
      }

      this.currentSelectedIndex = 0

      this.$emit('input', newValue)

      this.clearSearchInput()

      this.$nextTick(this.checkDropdownPosition)
    },
  },
}
